import React, { useEffect, useState } from 'react'
import { ImageField, BooleanField } from 'react-admin'
import { TickTockField } from './TickTockField'
import moment from 'moment'
import { makePrice, msToTime } from '../../../../utils'

export const AuctionInfo = ({ record, auctionData, fields }) => {
  const [liveInTimeLeft, setLTimeLeft] = useState(0)
  const [liveSinceTimeLeft, setLiveSinceTimeLeft] = useState(0)
  const [timeRemainingTimeLeft, setTimeRemainingTimeLeft] = useState(0)
  const auction = auctionData || record

  if (record.auctionType === 'sequential') {
    fields = fields.filter(field => {
      return field.source !== 'auctionMaxSeconds' && field.source !== 'standbyTimer'
    })
  }

  const TimeFormatMinutes = ({ parsed }) => {
    const milSeconds = parsed * 1000

    return (
      <span className='value'>  {msToTime(milSeconds)} </span>
    )
  }

  function secondsToHms (d) {
    if (typeof d !== 'number') {
      return d
    }
    d = Number(d)
    let h = Math.floor(d / 3600)
    let m = Math.floor(d % 3600 / 60)
    let s = Math.floor(d % 3600 % 60)

    let hDisplay = h > 0 ? h + ':' : '00:'
    let mDisplay = m > 0 ? (m < 10 ? '0' + m + ':' : m + ':') : '00:'
    let sDisplay = s < 10 ? '0' + s : s
    return hDisplay + mDisplay + sDisplay
  }

  useEffect(() => {
    const getLiveInTimeLeft = () => {
      if (!auction.approved) {
        setLTimeLeft(0)
        return
      }
      let localLiveAt = new Date(auction.liveAt)
      if (localLiveAt.getTime() > new Date().getTime()) {
        setLTimeLeft(localLiveAt.getTime() - new Date().getTime())
      } else {
        setLTimeLeft(0)
      }
    }

    const getLiveSinceTimeLeft = () => {
      if (!auction.approved) {
        setLiveSinceTimeLeft(0)
        return
      }
      if (new Date(auction.liveAt).getTime() < new Date().getTime()) {
        setLiveSinceTimeLeft(new Date().getTime() - new Date(auction.liveAt).getTime() - new Date(auction.inactiveTime).getTime())
      } else {
        setLiveSinceTimeLeft(0)
      }
      if (auction.state === 'open' || auction.state === 'live') {
        if (auction.staleTime) {
          setLiveSinceTimeLeft(new Date(auction.staleTime).getTime() - new Date(auction.liveAt).getTime() - new Date(auction.inactiveTime).getTime())
        }
      }
      if (auction.state === 'closed') {
        setLiveSinceTimeLeft(new Date(auction.finishAt).getTime() - new Date(auction.liveAt).getTime())
      }
    }

    const getTimeRemainingTimeLeft = () => {
      if (!auction.approved) {
        setTimeRemainingTimeLeft(0)
        return
      }
      if (timeRemainingTimeLeft < 0) {
        setTimeRemainingTimeLeft(0)
      }
      if ((auction.state === 'open' || auction.state === 'live') && auction.staleTime) {
        setTimeRemainingTimeLeft(0)
      }
    }

    getLiveInTimeLeft()
    getLiveSinceTimeLeft()
    getTimeRemainingTimeLeft()

    window.visibly.onVisible(() => {
      getLiveInTimeLeft()
      getLiveSinceTimeLeft()
      getTimeRemainingTimeLeft()
    })
  }, [auction, timeRemainingTimeLeft])

  return fields.map((field, index) => {
    let sources = field.source.split('.')
    let auction = auctionData || record
    let parsed = record
    if(field.label === 'Auction Type' && auction.auctionType === 'simultaneous'){
      auction.auctionType = 'Timed Auctions'
    }
    if (auction.listingFee && typeof auction.listingFee === 'number') {
      auction.listingFee = makePrice(auction.listingFee)
    }
    if (auction.specialPriceFee && typeof auction.specialPriceFee === 'number') {
      auction.specialPriceFee = makePrice(auction.specialPriceFee)
    }
    if (auction.auctionSetUpFee && typeof auction.auctionSetUpFee === 'number') {
      auction.auctionSetUpFee = makePrice(auction.auctionSetUpFee)
    }
    sources.forEach(source => {
      if (parsed) {
        parsed = parsed[source]
      }
    })
    if (parsed instanceof Array) {
      parsed = parsed.join(' / ')
    }
    if (auction.state === 'open' || auction.state === 'live') {
      auction._leftTime = new Date(auction.finishAt).getTime() - new Date().getTime()
      if (auction.auctionType === 'synchronous') {
        let staleTime = auction.frozenTime || auction.pausedTime
        if (staleTime) {
          auction._leftTime = new Date(auction.finishAt).getTime() - new Date(staleTime).getTime()
        } else {
          auction._leftTime = new Date(auction.finishAt).getTime() - new Date().valueOf()
        }
      } else {
        if (auction.staleTime) {
          auction._staleTime = new Date(auction.staleTime).getTime() - new Date(auction.liveAt).getTime() - new Date(auction.inactiveTime).getTime()
        } else {
          auction._staleTime = null
        }
      }
    }
    if (!auction._staleTime) {
      field.withTickTock = false
    }

    if (field.type === 'liveIn') {
      return (
        <div key={index} className='table-wrapper'>
          <span className='label'>{field.label} : &nbsp;</span>
          <TickTockField
            leftTime={liveInTimeLeft}
            stopTick={auction.pausedTime || auction.frozenTime}
            action='minus'
            className='value'
          />
        </div>
      )
    }

    if (field.type === 'time') {
      return (
        <div key={index} className='table-wrapper'>
          <span className='label'>{field.label} : &nbsp;</span>
          <span>{secondsToHms(auction[field.source])}</span>
        </div>
      )
    }

    if (field.type === 'tags') {
      return (
        <div key={index} className='table-wrapper'>
          <span className='label'>{field.label} : &nbsp;</span>
          <span>{auction[field.source].map(e => e.title).join(', ')}</span>
        </div>
      )
    }

    if (field.type === 'usefulLinks' && auction[field.source]) {
      if (auction[field.source].filter(link => link.url).length) {
        return (
          <>
            <div key={index} className='table-wrapper'>
              <span className='label'>{field.label} : &nbsp;</span>
              <span>{auction[field.source].map(e => <a target='_blank' rel='noopener noreferrer' style={{ marginLeft: '6px' }} href={e.url}>{e.description || e.url}</a>)}</span>
            </div>
          </>
        )
      } else {
        return null
      }
    }

    if (field.type === 'auctionTabs' && auction[field.source]) {
      if (auction[field.source].filter(link => link.tabName).length) {
        return (
          <>
            <div key={index} className='table-wrapper'>
              <span className='label'>{field.label} : &nbsp;</span>
              <span>{auction[field.source].map(e => 
                 <div key={index} className='auction-tabs'>
                 <p className='description'>{`${e.tabName} - ${e.description}`}</p>
               </div>
               )}</span>
            </div>
          </>
        )
      } else {
        return null
      }
    }

    if (field.type === 'liveSince') {
      let stop = false

      if (auction.state === 'closed') {
        stop = true
      }
      return (
        <div key={index} className='table-wrapper'>
          <span className='label'>{field.label} : &nbsp;</span>
          <TickTockField
            leftTime={liveSinceTimeLeft}
            stopTick={auction.pausedTime || auction.frozenTime || stop}
            action='add'
            className='value'
          />
        </div>
      )
    }

    if (field.type === 'timeRemaining') {
      let timeLeft = timeRemainingTimeLeft

      if (auction._leftTime && auction.state === 'live' && auction.approved) {
        timeLeft = auction._leftTime
      }

      return (
        <div key={index} className='table-wrapper'>
          <span className='label'>{field.label} : &nbsp;</span>
          <TickTockField
            leftTime={timeLeft}
            stopTick={auction.pausedTime || auction.frozenTime}
            action='minus'
            className='value'
          />
        </div>
      )
    }

    if (field.type === 'liveAt') {
      let localLiveAt = new Date(auction.liveAt)
      return (
        <div key={index} className='table-wrapper'>
          <span className='label'>{field.label} : &nbsp;</span>
          <span className='value'>{`${moment(localLiveAt).tz('Australia/Sydney').format('ddd, DD MMMM, h:mm A')} (SYD)`} <br /> <span style={{ fontSize: '15px' }}>{`${moment(localLiveAt).tz('Australia/Perth').format('h:mm A')} WA -- ${moment(localLiveAt).tz('Australia/Darwin').format('h:mm A')} NT -- ${moment(localLiveAt).tz('Australia/Queensland').format('h:mm A')} QLD -- ${moment(localLiveAt).tz('Australia/Adelaide').format('h:mm A')} SA -- ${moment(localLiveAt).tz('Australia/Sydney').format('h:mm A')} NSW -- ${moment(localLiveAt).tz('Australia/Victoria').format('h:mm A')} VIC -- ${moment(localLiveAt).tz('Australia/Tasmania').format('h:mm A')} TAS`}</span></span>
        </div>
      )
    }

    return (
      <div key={index} className='table-wrapper'>
        <span className='label'>{field.label} : &nbsp;</span>
        {field.withTickTock &&
          <TickTockField
            leftTime={auction._staleTime || auction._leftTime}
            stopTick={auction.pausedTime || auction.frozenTime}
            action='add'
            label='Time Left'
            source='leftTime'
            className='value'
          />
        }
        {field.type === 'boolean' &&
          <BooleanField record={record} source={field.source} className='value' sortable={false} />}

        {field.label === 'Image' && record.image &&
          <ImageField record={record} source='image.url' title='Icon' className='auction-image' />
        }
        {field.type === 'logo' && record.logo &&
          <ImageField record={record} source='logo.url' title='Icon' className='auction-image' />
        }
        {field.type === 'logoIos' && record.logoIos &&
          <ImageField record={record} source='logoIos.url' title='Icon' className='auction-image' />
        }
        {field.type === 'timeFormatMinutes' &&
          <TimeFormatMinutes parsed={parsed} />
        }
        {field.basePath &&
          <a href={`/#/kinds/${record.kind}/show`}>{record.kindData ? `${record.kindData.title}` : ''}</a>
        }
        {field.type === 'state' && <span className='value'> {auction.state.toUpperCase()} </span>}
        {!field.basePath &&
          !field.time && field.label !== 'Image' &&
          !field.withTickTock &&
          field.type !== 'timeFormatMinutes' &&
          field.type !== 'logo' &&
          field.type !== 'logoIos' &&
          field.type !== 'state' && field.type !== 'boolean' &&
          <span className='value'> {parsed} </span>
        }
      </div>
    )
  })
}
